<template>
    <v-menu
            offset-y
            left
            origin="center center"
            transition="scale-transition"
            v-model="notif_menu"
            min-width="260"
            max-width="260"
            rounded="lg"
            :close-on-content-click="false"
    >
        <template v-slot:activator="{ on }">
            <v-badge
                    :content="new_notifs"
                    :value="new_notifs && account_id !== null"
                    color="error"
                    overlap
                    offset-y="15"
                    offset-x="25"
            >
                <v-btn icon small v-on="on" @click="new_notifs = 0" v-if="show" class="mr-3">
                    <v-icon size="16">mdi-bell</v-icon>
                </v-btn>
            </v-badge>
        </template>

        <div class="cell">
            <div class="secondary px-3 py-4 text-center">
                <div class="sub-title-2 font-weight-bold white--text" v-if="notifications.length > 1">
                    {{ notifications.length }} {{ $tc('navbar.Notifications.notif', 2) }}
                </div>
                <div class="sub-title-2 font-weight-bold white--text" v-else>
                    {{ notifications.length }} {{ $tc('navbar.Notifications.notif', 1) }}
                </div>
            </div>

            <v-list class="py-0 transparent" style="max-height: 452px; overflow-y: auto; overflow-x: hidden" v-if="notifications.length > 0">
                <transition-group name="slide">
                    <v-list-item
                            v-for="(item,i) in notifications"
                            :key="item.id"
                            link
                            two-line
                            @click="seeNotification(item, i)"
                            class="ma-1 rounded-lg px-3 overflow-hidden"
                            :class="!item.seen ? 'elevation-2' : ''"
                            :style="!item.seen ? {backgroundColor: $tools.transparentize('secondary', 0.4, $vuetify)} : {}"
                    >
                        <v-list-item-content>
                            <v-list-item-title class="sub-title-2" :class="item.seen ? '':'font-weight-bold'">{{ item.contact[0] ? item.contact[0].display_name : '' }}</v-list-item-title>
                            <div class="transition-cubic font-weight-regular caption-1">{{ item.message }}</div>
                        </v-list-item-content>
                        <!--<v-list-item-action>
                            <v-tooltip top open-delay="500">
                                <template v-slot:activator="{ on }">
                                    <v-btn small v-on="on" icon @click.stop="deleteNotification(item, i)" :loading="loading">
                                        <v-icon size="18">mdi-delete</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $tc('global.action.delete', 1) }} {{ $tc('navbar.Notifications.notif', 1) }}</span>
                            </v-tooltip>
                        </v-list-item-action>-->
                    </v-list-item>
                </transition-group>
            </v-list>
            <v-divider class="mx-3 mt-2" v-if="notifications.length > 0" />
            <v-row class="mx-0" >
                <v-col cols="auto" class="py-2">
                    <v-tooltip top open-delay="500">
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" @click="dialog = true; notif_menu = false" x-small class="rounded-lg" height="36" color="cell2">
                                <v-icon size="18" color="secondary">mdi-format-list-bulleted</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $tc('global.action.view_all', 1) }}</span>
                    </v-tooltip>
                </v-col>
                <v-spacer />
                <v-col cols="auto" class="py-2">
                    <v-tooltip top open-delay="500">
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" @click="seeAllNotifications(); notif_menu = false" x-small class="rounded-lg" height="36" color="cell2">
                                <v-icon size="18" color="secondary">mdi-eye</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $tc('global.action.read_all', 1) }}</span>
                    </v-tooltip>
                </v-col>
                <!--<v-col cols="auto" class="py-2">
                    <v-tooltip top open-delay="500">
                        <template v-slot:activator="{ on }">
                            <v-btn :disabled="notifications.length === 0" v-on="on" @click="deleteNotificationAll()" x-small class="rounded-lg" height="36" color="cell2" :loading="loading">
                                <v-icon size="18" :color="$vuetify.theme.dark ? '':'cell3 darken-4'">mdi-delete-sweep</v-icon>
                            </v-btn>
                        </template>
                        <span class="paragraph">{{ $tc('global.action.delete_all', 1) }}</span>
                    </v-tooltip>
                </v-col>-->
            </v-row>
        </div>

        <Dialog v-model="dialog" title="Notifications" @close="dialog = false">
            <template v-slot:content>
                <v-list class="py-0 px-1 mb-1 transparent" style="max-height: 452px; overflow-y: auto; overflow-x: hidden">
                    <v-list-item
                            v-for="(item,i) in all_notifications"
                            :key="i"
                            link
                            two-line
                            @click="seeNotification(item, i)"
                            class="ma-1 rounded-lg px-3 overflow-hidden"
                            :class="!item.seen ? 'elevation-2' : ''"
                            :style="!item.seen ? {backgroundColor: $tools.transparentize('primary', 0.4, $vuetify)} : {}"
                    >
                        <v-list-item-content>
                            <v-list-item-title class="sub-title-2" :class="item.seen ? '':'font-weight-bold'">{{ item.contact[0] ? item.contact[0].display_name : '' }}</v-list-item-title>
                            <div class="transition-cubic font-weight-regular caption-1">{{ item.message }}</div>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-tooltip top open-delay="500">
                                <template v-slot:activator="{ on }">
                                    <v-btn small v-on="on" icon @click.stop="deleteNotification(item, i)">
                                        <v-icon size="18">mdi-delete</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $tc('global.action.delete', 1) }} {{ $tc('navbar.Notifications.notif', 1) }}</span>
                            </v-tooltip>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>
            </template>
        </Dialog>
    </v-menu>
</template>

<script>
    export default {
        name: "Notifications",
        props: ['show'],
        components: {
            Dialog: () => import('@/components/ui/Dialog.vue')
        },
        data(){
            return {
                loop: null,
                new_notifs: 0,
                notif_menu: false,
                notifications: [],
                all_notifications: [],
                notifs: 0,
                dialog: false,
                loading: false
            }
        },
        computed: {
            account_id() {
                return this.$store.getters['auth/account_id']
            }
        },
        watch: {
            notifs(new_val, old_val){
                if(new_val > old_val) {
                    this.new_notifs = new_val - old_val
                }
                else {
                    if(this.new_notifs !== 0){
                        this.new_notifs = new_val
                    }
                }
            }
        },
        methods: {
            deleteNotificationAll(){
                for(let index = 0; index < this.notifications.length; index++){
                    let notif = this.notifications[index]

                    this.deleteNotification(notif, index)
                }
            },
            deleteNotification(notif, index){
                this.loading = true
                this.$wsc.deleteItem('notification', notif.id, success => {
                    this.notifications.splice(index, 1)
                    this.all_notifications.splice(index, 1)

                    if(this.notifications.length === 0){
                        this.notif_menu = false
                    }
                    this.loading = false
                    this.$store.dispatch('snackbar/success', this.$t('snackbar.Notifications.delete.success'))
                }, fail => {
                    this.$store.dispatch('snackbar/error', this.$t('snackbar.Notifications.delete.fail'))
                    this.loading = false

                })
            },
            seeAllNotifications(){
                this.notifications.forEach(n => {
                    this.$wsc.patchItem('notification', n.id, { seen: true }, () => {
                        n.seen = true
                        let i = this.notifications.findIndex(no => no.id === n.id)
                        this.notifications.splice(i, 1)
                    }, error => {
                        n.open = false
                    })
                })
            },
            seeNotification(notif, index) {
                if(!notif.seen){
                    this.$wsc.patchItem('notification', notif.id, { seen: true }, () => {
                        notif.seen = true
                        
                        this.notifications.splice(index, 1)
                    }, error => {
                        notif.open = false
                    })
                }
            },
            getNotifications () {
                if (this.$store.getters['auth/account_id']) {
                    this.$wsc.getList('notification', {}, n => {
                        this.all_notifications = n
                        let res = []

                        n.forEach(e => {
                            e.open = false
                            e.contact.forEach( c => {
                                if(c.id === this.$store.getters['auth/contact_id'] && !e.seen){
                                    res.push(e)
                                }
                            })
                        });

                        this.notifs = res.length
                        this.notifications = res

                        if(this.notifications.length === 0){
                            this.notif_menu = false
                        }
                    }, error => {})
                }
            }
        },
        created () {
            if (this.$store.getters['auth/account_id']) {
                this.$wsc.getList('notification', {}, n => {
                    this.all_notifications = n
                    let res = []

                    n.forEach(e => {
                        e.open = false
                        e.contact.forEach( c => {
                            if(c.id === this.$store.getters['auth/contact_id'] && !e.seen){
                                res.push(e)
                            }
                        })
                    });

                    this.notifs = res.length
                    this.notifications = res

                    if(this.notifications.length === 0){
                        this.notif_menu = false
                    }
                }, error => {})
            }

            this.loop = window.setInterval(() => {
                this.getNotifications()
            }, 15000)
        },
        beforeDestroy () {
            clearInterval(this.loop)
        }
    }
</script>
